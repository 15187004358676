import React from 'react';

import {
  ColoredSection,
  Header,
  HeroImage,
  LayoutUI,
  Stack,
  TextStyle,
  TwoUp,
  COLORS
} from '../../ui-kit';
import Layout from '../../components/layout';
import Navbar from '../../components/navbar/navbar';
import MobileNavbar from '../../components/mobile-navbar/mobile-navbar';

import { randomNumberBetween } from '../../utils/random';
import RainbowDot from '../../assets/icons/rainbow-dot.svg';
import QuestionMarkIcon from '../../assets/icons/question-mark.svg';
import PhoneIcon from '../../assets/icons/phone.svg';
import HouseIcon from '../../assets/icons/house.svg';
import * as styles from './Contact.module.scss';

const CONTENT = {
  CARDS: [
    {
      title: 'Educational Curriculum',
      color: COLORS.YELLOW,
      email: 'education@projecthappiness.org'
    },
    {
      title: 'Global Involvement',
      color: COLORS.PURPLE,
      email: 'ambassadors@projecthappiness.org'
    },
    {
      title: 'Donations and Partnerships',
      color: COLORS.BLUE,
      email: 'development@projecthappiness.org'
    },
    {
      title: 'Speaking Engagements and Trainings',
      color: COLORS.TEAL,
      email: 'events@projecthappiness.org'
    },
    {
      title: 'Write for Us',
      color: COLORS.RED,
      email: 'blog@projecthappiness.org'
    },
    {
      title: 'Technical Support',
      color: COLORS.BLUE,
      email: 'help@projecthappiness.org'
    },
    {
      title: 'General Inquiries',
      color: 'ICON',
      email: 'hello@projecthappiness.org'
    }
  ]
};

const HEADER_ICON_POSITIONS = [
  [-400, 40],
  [-350, -100],
  [-315, 100],
  [-220, -55],
  [-120, -100],
  [50, -75],
  [170, -120],
  [290, 10],
  [310, -120],
  [350, 100]
];

const Contact = () => {
  const rotatedIconsMarkup = HEADER_ICON_POSITIONS.map(([x, y]) => {
    const rotation = randomNumberBetween(-50, 50);
    const stylesImage = {
      transform: `translate3d(${x}px, ${y + 20}px, 0) rotate(${rotation}deg)`
    };

    return (
      <img
        className={styles.QuestionMark}
        key={`${x}:${y}:${rotation}`}
        alt=""
        src={QuestionMarkIcon}
        style={stylesImage}
      />
    );
  });

  const contactCardsMarkup = CONTENT.CARDS.map(({ title, color, email }) => (
    <div className={styles.Card} key={title}>
      <div className={styles.CardContent}>
        <Stack vertical distribution="equalSpacing">
          <Stack.Item fill>
            {color === 'ICON' ? (
              <img src={RainbowDot} className={styles.ContactCircle} alt="" />
            ) : (
              <div
                className={styles.ContactCircle}
                style={{ backgroundColor: color }}
              />
            )}
          </Stack.Item>
          <Stack.Item>
            <Header size="small">{title}</Header>
            <p className="lead">
              <a href={`mailto:${email}`} className={styles.ContactLink}>
                <TextStyle color="gray-darker">{email}</TextStyle>
              </a>
            </p>
          </Stack.Item>
        </Stack>
      </div>
    </div>
  ));

  return (
    <Layout>
      <Navbar color="white" />
      <MobileNavbar color="white" />
      <div>
        <div className={styles.HeroImage}>
          <HeroImage backgroundColor="#FF6E4A">
            <LayoutUI narrow>
              <Stack alignment="center" distribution="center" vertical>
                <Stack.Item>{rotatedIconsMarkup}</Stack.Item>
                <Stack.Item>
                  <TextStyle color="white" center>
                    <div className={styles.Header}>
                      <Header>
                        Questions? Comments? Ask us anything, we&apos;d love to
                        hear from you.
                      </Header>
                    </div>
                  </TextStyle>
                </Stack.Item>
              </Stack>
            </LayoutUI>
          </HeroImage>
        </div>

        <LayoutUI>
          <div className={styles.CardContainer}>{contactCardsMarkup}</div>
        </LayoutUI>

        <ColoredSection color="orange-light">
          <div className={styles.Footer}>
            <TextStyle color="white">
              <LayoutUI>
                <TwoUp>
                  <Stack>
                    <Stack.Item>
                      <img
                        src={HouseIcon}
                        alt=""
                        className={styles.FooterIcon}
                      />
                    </Stack.Item>
                    <Stack.Item fill>
                      <Header size="small">Project Happiness</Header>
                      <address>
                        20370 Town Center Lane
                        <br />
                        Suite 254
                        <br />
                        Cupertino, CA 94014
                      </address>
                    </Stack.Item>
                  </Stack>
                  <Stack>
                    <Stack.Item>
                      <img
                        src={PhoneIcon}
                        alt=""
                        className={styles.FooterIcon}
                      />
                    </Stack.Item>
                    <Stack.Item fill>
                      <Header size="small">(669) 292-5509</Header>
                    </Stack.Item>
                  </Stack>
                </TwoUp>
              </LayoutUI>
            </TextStyle>
          </div>
        </ColoredSection>
      </div>
    </Layout>
  );
};

export default Contact;
